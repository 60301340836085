.embla {
  --slide-spacing: 1rem;
  --slide-size: 60%;
  --slide-height: 50vh;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
  flex-direction: row;
  height: auto;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}

.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.embla__slide_visited {
  width: 10rem;
  height: 3.6rem;
  z-index: 1;
  position: absolute;
  top: 0rem;
  right: -4rem;
  background-color: #0072bc;
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
  transform: rotate(45deg);
}
.embla__slide_youtube {
  width: 8rem;
  height: 3rem;
  z-index: 1;
  position: absolute;
  top: 0rem;
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}

.embla__slide_visited > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  transform: rotate(-45deg);
}

.embla__class-names {
  transition: opacity 0.2s ease-in-out;
  transition: scale 0.2s ease-in-out;
  overflow: hidden;
}

.embla__class-names:not(.is-selected) {
  opacity: 0.49;
  scale: 0.81;
  z-index: -1;
}

.navigate_icon {
  position: absolute;
  right: 0;
  top: 40%;
}

.embla_photo {
  position: relative;
  background-color: #f7f7f7;
  margin-left: auto;
  margin-right: auto;
}

.embla__viewport_photo {
  overflow: hidden;
  width: 100%;
}

.embla__viewport.is-draggable_photo {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging_photo {
  cursor: grabbing;
}

.embla__container_photo {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
}

.embla__slide_photo {
  position: relative;
  min-width: 100%;
  padding-left: 10px;
}

.embla__slide__inner_photo {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.embla__slide__img_photo {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: auto;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
}

.embla__button {
  z-index: 1;
  color: var(--background-site);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 4rem;
  height: 4rem;
}

.embla__button--prev {
  left: 1.6rem;
}

.embla__button--next {
  right: 1.6rem;
}