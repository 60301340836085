@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,400;8..144,500;8..144,600;8..144,700;8..144,800&display=swap');


.notofication-content {
  height: 10vh;
  box-shadow: 0px 4px 4px rgba(190, 190, 190, 0.25);
}


.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
  position: relative;
  

}


#myvideo, #photogrid {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
} 


.media-title {
  display: flex;
  justify-content: center;
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: #3F3F3F;
  text-align: center;
}

iframe {
  height: 100%;
  width: 100%;
}



.thumbnail_item {
      filter: brightness(50%)
}

.embla__slide > .selected-img {
  padding: 0;
  height: 100%;
  position: relative;
  z-index: 1;
}

.visited {
  position: absolute;
  right: 5px;
  border: 1px solid black;
  background-color: green;
  color: black;
  border-radius: 20%;
  z-index: 99;
}


.btn-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0%;
  left: 38%;
}

.error_screen {
  width: 100%;
  height: 100%;
}