.sandbox {
    width: 100%;
  }
  @media (min-width: 750px) {
    .sandbox {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }
  }
  .sandbox__carousel {
    position: relative;
    background-color: var(--background-code);
  }
  @media (max-width: 749px) {
    .sandbox__carousel {
      border-top: 0.1rem solid var(--detail-low-contrast);
      border-bottom: 0.1rem solid var(--detail-low-contrast);
    }
  }
  @media (min-width: 750px) {
    .sandbox__carousel {
      border-radius: 0.4rem;
      border: 0.1rem solid var(--detail-low-contrast);
    }
  }